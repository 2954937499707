import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/home/Home'
import Navbar from './components/Navbar'
import Socials from './pages/socials/Socials'
import MatchHistory from './pages/matchhistory/MatchHistory'
import NotFound from './pages/notFound/NotFound'
import LbPage from './pages/leaderboard/LbPage'
import Stats from './pages/stats/Stats'
import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'

const App = () => {
    const [cookies, setCookie] = useCookies(['sets'])
    if (!('sets' in cookies)) {
        setCookie('sets', ['set00', 'set01'])
    }
    // IN DEPTH STATS PAGE FOR EACH GAME (opleague.pro/match/32815)-> AND MATCHES EXPAND ON HOVER TO SHOW SOME STATS
    return (
        <BrowserRouter>
            <Navbar/>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path="/leaderboard" element={<LbPage/>}/>
                {/* <Route path="/stats" element={<Stats/>}/> */}
                <Route path="/matchhistory" element={<MatchHistory/>}/>
                <Route path="socials" element={<Socials/>}/>
                <Route path="*" element={<NotFound/>}/>
             </Routes>
        </BrowserRouter>
    )
}

export default App