import { Component } from 'react'

class PlayerStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            player: props.player,
            stats: []
        }
    }
  
    async getJson(path) {
        const response = await fetch(path, {headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'}})
            
        const json = await response.json()
        return json
    }
  
    async componentDidMount() {
        const stats = await this.getJson(`./data/brio10s/players/${this.state.player}.json`)
        
        this.setState({ stats: stats})
    }
    render() {
      return (
        <div className='player-stats'>
            <div className='player-header'></div>
            {
                
            }
        </div>
      )
    }
}
  
export default PlayerStats