import { Component } from 'react'
import LbStanding from './LbStanding'
import LbHeader from './LbHeader'
import './leaderboard.css'

class Leaderboard extends Component {
    constructor() {
        super();
        this.state = {
            stats: []
        }

        window.addEventListener('storage', async () => {
            const szn = this.decodeCookie(document.cookie)[0]
            await this.comp_data(szn)
        })
    }

    decodeCookie(cookiestr) {
        const obj = {}
        const items = cookiestr.split(';').map(v => [[v.split('=')[0].trim()], decodeURIComponent(v.split('=')[1])])
        items.forEach(i => {
            obj[i[0]] = i[1]
        })
        return JSON.parse(obj['sets'])
    }
  
    async getJson(path) {
        const headers = new Headers()
        headers.append('pragma', 'no-cache');
        headers.append('cache-control', 'no-cache')
        headers.append('Content-Type', 'application/json')
        headers.append('Accept', 'application/json')
        
        const myInit = {
            method: 'GET',
            headers: headers
        }

        const request = new Request(path)

        const response = await fetch(request, myInit)
            
        const json = await response.json()
        return json
    }

    async loadStats(info, set) {        
        const stats = await this.getJson(`./data/brio10s/sets/${set}/allPlayers.json`)
        // for (const player of info.players) {
        //     stats.push(await this.getJson(`./data/brio10s/sets/${set}/players/${player}.json`))
        // }

        const items = stats.map(ele => [ele.playername, ele.stats])
        items.sort((f, s) => s[1].elo - f[1].elo || s[1].kills - f[1].kills)

        return items
    }

    async comp_data(set) {
        const info = await this.getJson(`./data/brio10s/sets/${set}/info.json`)
        
        const stats = await this.loadStats(info, set)

        this.setState({ stats: stats })
    }
  
    async componentDidMount() {
        await this.comp_data(this.decodeCookie(document.cookie)[0])
    }

    render() {
      return (
        <div className='leaderboard'>
            {<LbHeader/>}
            {
                this.state.stats.map((ele, index) => {
                    if ((ele[1].games_won + ele[1].games_lost) >= 1) {
                        return (
                            <LbStanding key={ele[0]} index={index} player={ele[0]} stats={ele[1]}/>
                        )
                    }
                })
            }
        </div>
      )
    }
}
  
export default Leaderboard