import { Link } from "react-router-dom"
import Image from "../images/pfp_base.png"

function MainHeader() {
  return (
    <header className="main__header">
      <div className="main__header-container">
        <div className="main__header-left">
          <h4>welcome</h4>
          <h1>briyohs</h1>
          <p>
            currently in development
          </p>
          {/* <Link to="/plans" className='btn lg'>Get Started</Link> */}
        </div>
        <div className="main__header-right">
          <div className="main__header-circle"></div>
          <div className="main__header-image">
            <img className="header-img" src={Image} alt="Main Header Image"/>
          </div>
        </div>
      </div>
    </header>
  )
}

export default MainHeader