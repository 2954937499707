export const links = [
    {
        name: 'About',
        path: '/'
    },
    {
        name: 'Leaderboard',
        path: '/leaderboard'
    },
    {
        name: 'Matches',
        path: '/matchhistory'
    },
    {
        name: 'Socials',
        path: '/socials'
    }
]