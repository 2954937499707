const About = () => {
  return (
    <div className="about-container">
        <h1>brio10s</h1>
        <a className="entry-form" href="https://forms.gle/X8FMoikVLv1LVNZB6">Entry Form</a>
        <h2>prizes</h2>
        <h3>1st: $125 - 2nd: $75 - 3rd: $50</h3>
        <h2>format/ranking</h2>
        <span>unsure on the duration of the first set yet</span>
        <br />
        <span>teams will always be decided on via snake draft with 2 captains</span>
        <br />
        <span>map through map ban</span>
        <br />
        <span>undecided on elo gain/loss, but will most likely be set gain/loss amounts</span>
        <br />
        <span>might do something with winstreak or enemy team average, just depends on what everyone wants</span>
        <h2>game settings</h2>
        <span>normal competitive settings</span>
    </div>
  )
}

export default About
