import OperatorSet from './OperatorSet'

const getRank = (elo) => {
    if (elo >= 500) {
        return 'omega'
    } else if (elo >= 400){
        return 'alpha'
    } else if (elo >= 300){
        return 'beta'
    } else if (elo >= 200){
        return 'gamma'
    } else if (elo >= 100){
        return 'delta'
    } else {
        return 'zeta'
    }
}

const BrioStanding = ({index, player, stats}) => {
    const rw = stats.rounds_won['atk'] + stats.rounds_won['def']
    const rl = stats.rounds_lost['atk'] + stats.rounds_lost['def']
    const rank = getRank(stats.elo)

    var class_name = `player-standing rank-${rank}`

    if (index == 0) {
        class_name = `player-standing rank-one`
    }
    
    return (
        <div className={`${class_name}`}>
            <div className="row-item">{index+1}</div>
            <div className="row-item">{player}</div>
            <div className="row-item">{stats.elo}</div>
            <div className="row-item">{`${stats.kills}-${stats.deaths}`}</div>
            <div className="row-item">{`${parseFloat(stats.kills/stats.deaths).toFixed(2)}`}</div>
            <div className="row-item">{`${parseFloat(stats.games_won / (stats.games_won + stats.games_lost)* 100).toFixed(0)  +"%"}`}</div>
            <div className="row-item">{`${stats.games_won + stats.games_lost}`}</div>
            <div className="row-item">{(rw - rl) >= 0 ? `+${rw - rl}` : `${rw - rl}`}</div>
            <div className="row-item">{`${stats.fk} - ${stats.fd}`}</div>
            <div className="row-item">{(stats.kills == 0 ? 0 : (stats.headshots/stats.kills)*100).toFixed(0) +"%"}</div>
            <OperatorSet key={player} ops_played={stats.ops_played} amount={1}/>
            {/* <div className="row-item">{`${stats.kills} / ${stats.deaths}`}</div> */}
            {/* <div className="row-item">{`${stats.games_won + stats.games_lost}`}</div> */}
            {/* <div className="row-item">{`${stats.games_won} - ${stats.games_lost}`}</div> */}
            {/* <div className="row-item">{(rw - rl) >= 0 ? `+${rw - rl}` : `${rw - rl}`}</div> */}
        </div>
    )
}

export default BrioStanding