import { Component } from "react";
import { useCookies } from 'react-cookie'

const SeasonMenu = () => {
    const [cookies, setCookie] = useCookies(['sets'])
    const changeSeason = (value) => {
        if (value.target.textContent === cookies['sets'][0]) {
            return
        }
        const szns = cookies['sets']
        szns.sort((f, s) => {
            return +(s !== value.target.textContent) * -1
        })
        setCookie('sets', szns)
        window.dispatchEvent(new Event('storage'))
    }

    return (
        <div className='season-list'>
            <div id={`op_0`} className='season-option' onClick={(value) => changeSeason(value)}>
                <h3>{cookies['sets'][0]}</h3>
            </div>
            <div id={`op_1`} className='season-option' onClick={(value) => changeSeason(value)}>
                <h3>{cookies['sets'][1]}</h3>
            </div>
            {/* <div id={`op_2`} className='season-option' onClick={(value) => changeSeason(value)}>
                <h3>{this.state.seasons[2]}</h3>
            </div> */}
        </div>
    )
}

export default SeasonMenu