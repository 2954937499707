const OperatorSet = ({ops_played, amount}) => {
	const ops = {
		'atk': Object.keys(ops_played['atk']).map(k => [k, ops_played['atk'][k]]),
		'def': Object.keys(ops_played['def']).map(k => [k, ops_played['def'][k]])
	}

	ops.atk.sort((f, s) => s[1] - f[1])
	ops.def.sort((f, s) => s[1] - f[1])

	return (
		<div className="row-item set">
			<div className="icon-set">
				{
					ops['atk'].slice(0, amount).map(ele => {
					const opname = ele[0].toString().toLowerCase()
					return (<img key='atk' src={`./images/operators/${opname}.png`} alt={ele[0]} className="op-icon"/>)})
				}
				{
					ops['def'].slice(0, amount).map(ele => {
					const opname = ele[0].toString().toLowerCase()
					return (<img key='def' src={`./images/operators/${opname}.png`} alt={ele[0]} className="op-icon"/>)})
				}
			</div>
		</div>
	)
}

export default OperatorSet