import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom'
import Logo from '../images/pfp_circle.png'
import { links } from '../data/data'
import { TiThMenu } from "react-icons/ti";
import { MdOutlineClose } from "react-icons/md";
import SeasonMenu from './SeasonMenu';
import { useCookies } from 'react-cookie'
import './navbar.css'

const Navbar = () => {
    const [isNavShowing, setIsNavShowing] = useState(false)
    return (
        <nav>
            <div className="container nav__container">
                <Link to='/' className='logo'>
                    <img src={Logo} alt="Nav Logo" />
                    <h2>briyohs</h2>
                </Link>
                 <div className="season-menu">
                    <SeasonMenu/>
                </div>
                <ul className={`nav__links ${isNavShowing ? 'show__nav' : 'hide__nav'}`}>
                    {
                        links.map(({name, path}, index) => {
                            return (
                                <li key={index}>
                                    <NavLink to={path} className={({isActive}) => isActive ? 'active-nav' : ''}>{name}</NavLink>
                                </li>
                            )
                        })
                    }
                </ul>
                <button className='nav__toggle-btn' onClick={() => setIsNavShowing(!isNavShowing)}>
                    {
                        isNavShowing ? <MdOutlineClose/> : <TiThMenu/>
                    }
                </button>
            </div>
        </nav>
    )
}

export default Navbar