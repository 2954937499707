import Leaderboard from './Leaderboard'
import './leaderboard.css'

const LbPage = () => {
    return (
        <div className='standings-container'>
            <Leaderboard/>
        </div>
    )
}

export default LbPage