const headers = ['#', 'PLAYER', 'ELO', 'K-D', 'KDR', 'WR', 'M', 'RD', 'ENTRY', 'HS%', 'MOST PLAYED']

const LbHeader = () => {
  return (
    <div className='player-standing header'>
        {
            headers.map(ele => {
                return (
                    <div key={ele} className='row-item'>{ele}</div>
                )
            })
        }
    </div>
  )
}

export default LbHeader